import { AUTH, loginWithRedirect } from "@betnbet/front-sdk/dist/auth"
import { setCookie } from "@betnbet/front-sdk/dist/util/cookie"
import { useEffect, useState } from "react"
import { TMe } from "../payload"

type TStoreProps = {
  widget?: TWidgetConfig
  user?: TMe
  auth0_domain: string
  auth0_client_id: string
}

export type TStore = {
  pageLoader: TPageLoaderStore
  modal: TModalStore
  widget: TWidgetStore
  auth: TAuthStore
  snack: TSnackStore
}
export const useStore = (props: TStoreProps): TStore => {
  const store: TStore = {
    pageLoader: usePageLoader(),
    modal: useModal(),
    snack: useSnack(),
    widget: useWidget(props.widget),
    auth: useAuth(props),
  }

  return store
}

type TPageLoaderStore = {
  isLoading: boolean
  show: () => void
  hide: () => void
}
const usePageLoader = (): TPageLoaderStore => {
  const [isLoading, setLoading] = useState<boolean>(false)
  const show = () => setLoading(true)
  const hide = () => setLoading(false)
  return { isLoading, show, hide }
}

type TModalConfig = {
  show: boolean
  headerText: string
  children: ((close: () => void) => React.ReactNode) | null
}
type TModalStore = TModalConfig & {
  setModal: React.Dispatch<React.SetStateAction<TModalConfig>>
}
const useModal = (): TModalStore => {
  const [modal, setModal] = useState<TModalConfig>({
    show: false,
    headerText: "",
    children: null,
  })
  return { ...modal, setModal }
}

export type TWidgetConfig = {
  main: {
    type: "hide" | "show" | "show if its you or no bk"
    bkSlug?: string | null
  }
  catfish: {
    show: boolean
    bkSlug?: string
  }
  popup: {
    show: boolean
    bkSlug: string | null
  }
}
type TWidgetStore = TWidgetConfig & {
  setWidgetConfig: React.Dispatch<React.SetStateAction<TWidgetConfig>>
}
const useWidget = (
  defaultWidget: TWidgetConfig = {
    main: {
      bkSlug: undefined,
      type: "hide",
    },
    catfish: {
      show: false,
      bkSlug: undefined,
    },
    popup: {
      show: false,
      bkSlug: null,
    },
  }
): TWidgetStore => {
  const [widget, setWidgetConfig] = useState<TWidgetConfig>(defaultWidget)
  return { ...widget, setWidgetConfig }
}

type TAuthProps = Pick<TStoreProps, "user" | "auth0_client_id" | "auth0_domain">

type TAuthStore = {
  user: TMe | undefined
  setUser: React.Dispatch<React.SetStateAction<TMe | undefined>>
  userLoaded: boolean
  login: () => Promise<void>
  logout: () => void
}
const useAuth = (props: TAuthProps): TAuthStore => {
  const { user: me, auth0_domain, auth0_client_id } = props
  const [user, setUser] = useState<TMe | undefined>(me)

  const userLoaded = Boolean(user)

  const login = async () => {
    setCookie(AUTH.REDIRECT, window.location.href, 1)
    loginWithRedirect(
      auth0_domain,
      auth0_client_id,
      window.location.origin + AUTH.CB
    )
  }

  const logout = () => {
    window.location.pathname = "/logout"
  }
  return { user, setUser, userLoaded, login, logout }
}

export type TSnackProps = {
  type: "success" | "error" | "info"
  message: string
  button?: {
    title: string
    action: () => void
  }
}

const delay = 250
const duration = 8000

type TSnackStore = {
  addSnack: (snack: TSnackProps) => void
  closeSnack: () => void
  current: TSnackProps | null
}

const useSnack = (): TSnackStore => {
  const [current, setCurrent] = useState<TSnackProps | null>(null)

  const addSnack = (new_snack: TSnackProps) => {
    if (current) {
      closeSnack()
      setTimeout(() => setCurrent(new_snack), delay)
    } else {
      setCurrent(new_snack)
    }
  }

  const closeSnack = () => {
    setCurrent(null)
  }

  useEffect(() => {
    const timer = setTimeout(() => closeSnack(), duration)
    if (!current) clearTimeout(timer)
    return () => clearTimeout(timer)
  }, [current])

  return { addSnack, closeSnack, current }
}
