import { useMediaQuery } from "@material-ui/core"
import { useEffect } from "react"
import { useLocation } from "react-router"
import { useDi } from "../App/di"

type TPixelHook = (src?: string | null, mobileSrc?: string | null) => void

export const usePixel: TPixelHook = (src, mobileSrc) => {
  const { pathname } = useLocation()
  const { theme } = useDi()
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm")) || false

  useEffect(() => {
    if (!src) return

    if (mobileSrc && !isDesktop) {
      loadImg(mobileSrc)
    } else {
      loadImg(src)
    }
    console.log("pixel load ", src)
  }, [pathname])
}

const loadImg = (url: string) =>
  fetch(url, { cache: "reload", mode: "no-cors" })
