import React, { FC, useState } from "react"
import { useDi } from "../App/di"
import { Icon } from "../slider/icons"

type TWidgetInfoProps = {
  info: string | null
  link: string | null
  hide: () => void
  up_side?: boolean
  className?: {
    more: string
    more_icon: string
    actions: string
    widget_info: string
    hide_widget: string
    copy_link: string
  }
}

export const WidgetInfo: FC<TWidgetInfoProps> = ({
  info,
  link,
  hide,
  className,
}) => {
  const {
    store: { snack },
    lang,
  } = useDi()

  const [open, setOpen] = useState<boolean>(false)

  const copy = async () => {
    await navigator.clipboard.writeText(link || "")
    setOpen(!open)
    snack.addSnack({
      type: "success",
      message: lang.widget__snack_message__copied,
    })
  }

  const close = () => {
    hide()
    setOpen(!open)
    snack.addSnack({
      type: "success",
      message: lang.widget__snack_message__hidden,
    })
  }

  return (
    <>
      <div className={className?.more} onClick={() => setOpen(!open)}>
        <Icon
          name={open ? "close" : "more_vertical"}
          className={className?.more_icon}
          width={20}
          height={20}
        />
      </div>
      {open && (
        <div className={className?.actions}>
          {info && <div className={className?.widget_info}>{info}</div>}
          <div className={className?.hide_widget} onClick={close}>
            {lang.widget__option_hide}
          </div>
          <div className={className?.copy_link} onClick={copy}>
            {lang.widget__option_copy_link}
          </div>
        </div>
      )}
    </>
  )
}
