export type TEnv = {
  IS_BROWSER?: boolean
  IS_WORKER?: boolean
  CDN: string
  UPLOAD_ORIGIN: string
  WEBP: boolean
  AUTH0_DOMAIN: string
  AUTH0_CLIENT_ID: string
  AUTH: number
  NODE_ENV: string
  IS_PROD: boolean
  WDS_PORT: string
  DOMAIN: string
}

export const getEnv = (): TEnv => {
  const processEnv: TEnv = process.env as any
  const target: TEnv = processEnv.IS_WORKER
    ? self
    : processEnv.IS_BROWSER
    ? (window as any).env
    : processEnv

  return {
    CDN: target.CDN || "https://cdn.betnbet.ru",
    UPLOAD_ORIGIN: target.UPLOAD_ORIGIN,
    WEBP: target.WEBP,
    AUTH0_DOMAIN: target.AUTH0_DOMAIN,
    AUTH0_CLIENT_ID: target.AUTH0_CLIENT_ID,
    AUTH: target.AUTH,
    NODE_ENV: target.NODE_ENV,
    IS_PROD: target.NODE_ENV === "production",
    WDS_PORT: target.WDS_PORT,
    DOMAIN: target.DOMAIN,
  }
}
