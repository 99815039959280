import {
  isAsyncComponent,
  TAsyncComponent,
} from "@betnbet/front-sdk/dist/Common/Chunk"
import { Location } from "history"
import React, { createElement, FC, useContext } from "react"
import {
  matchRoutes,
  Route,
  RouteMatch,
  RouteObject,
  Routes,
} from "react-router"
import { TBranchItem } from "story"
import { TStoryDeps } from "../App/di"
import { TApiKey } from "../graphql/graphql-sdk"
import { TRouteResponse } from "../payload"
import { TWidgetConfig } from "../store/store"
import { TAppStory, TLoadData, TLoadDataProps } from "./route-types"

export type TGetBranch = (matches: TRouteMatch[]) => TBranchItem[]
type TDataRoutesProps = {
  routes: TAppRouteConfig[]
  story: TAppStory
}
export const DataRoutes: FC<TDataRoutesProps> = ({ routes, story }) => {
  return (
    <Routes>
      {routes.map((route) => {
        const key = route.dataKey && story.state.keys[route.dataKey]
        const data = key && story.data[key]
        return (
          <Route
            key={route.dataKey + "!" + key}
            path={route.path}
            caseSensitive={route.caseSensitive}
            element={
              <RouteCtx.Provider
                value={{
                  data,
                  route: route,
                  abortController: story.state.abortController,
                }}
              >
                {route.element}
              </RouteCtx.Provider>
            }
          />
        )
      })}
    </Routes>
  )
}
type TRouteCtx = {
  data: any
  route: TAppRouteConfig
  abortController?: AbortController
}
const RouteCtx = React.createContext<TRouteCtx>(null as any as TRouteCtx)
export const RouteWrapper: FC = () => {
  const ctx = useContext(RouteCtx)
  const props = {
    route: ctx.route,
    abortController: ctx.abortController,
    ...ctx.data,
  }
  return createElement(ctx.route.component, props)
}

export type TAppRouteConfig = RouteObject & {
  component: React.ElementType
  children?: TAppRouteConfig[]
  dataKey?: string
  loadData?: TLoadData<any>
}
export type TRouteMatch = RouteMatch & { route: TAppRouteConfig }

export type TAppBranchItem = TBranchItem & { match: TRouteMatch }

export const createBranchItemMapper =
  (story: TAppStory, deps: TStoryDeps) =>
  (
    branchItem: TAppBranchItem,
    abortController: AbortController
  ): [TLoadDataProps<{}>, TStoryDeps] => {
    return [
      {
        story,
        abortController,
        match: branchItem.match,
        set404: () => story.setStatus(404),
      },
      deps,
    ]
  }

export type TRouteComponentProps<D> = {
  route: TAppRouteConfig
  abortController?: AbortController
} & D

export function loadBranchComponents(
  matches: TRouteMatch[]
): Promise<React.ComponentType[]> {
  return Promise.all(
    matches.map((match) => {
      const component = match.route.component as
        | React.ComponentType
        | TAsyncComponent
      if (isAsyncComponent(component)) {
        return component.load()
      }
      return component
    })
  )
}

type TloadBranchReturn = { widget: TWidgetConfig | undefined }
type TApiKeyLoadable = Extract<TApiKey, "academy">
export const loadBranch = async (
  story: TAppStory,
  routes: TAppRouteConfig[],
  location: Location,
  isPush: boolean = false
): Promise<TloadBranchReturn> => {
  let widget: TloadBranchReturn["widget"] = undefined
  const matches: TRouteMatch[] =
    (matchRoutes(routes, location) as TRouteMatch[]) || []
  const mappedRoutes = matches.map((m) => {
    const load =
      m.route.dataKey && !m.route.loadData
        ? ((async (s, d) => {
            const action: any = d.api[m.route.dataKey as TApiKeyLoadable]
            const signal = process.env.IS_WORKER
              ? null
              : s.abortController.signal
            const r: TRouteResponse<any> = await action(signal, s.match.params)
            if (r.statusCode === 404) s.set404()
            if (r.widget) {
              widget = r.widget
            }
            return r.data
          }) as TLoadData<any>)
        : m.route.loadData

    const branchItem: TAppBranchItem = {
      url: m.pathname,
      load,
      key: m.route.dataKey,
      match: m,
    }
    return branchItem
  })
  await Promise.all([
    story.loadData(mappedRoutes, location, isPush),
    loadBranchComponents(matches),
  ])
  return { widget }
}
