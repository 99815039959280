import { debounce } from "@betnbet/front-sdk/dist/util/debounce"
import { scrollBody } from "@betnbet/front-sdk/dist/util/scroll-body"
import clsx from "clsx"
import React, { FC, useCallback, useRef, useState } from "react"
import { useDi } from "../App/di"
import { SearchPopup } from "../layout/nossr"
import { TSearch } from "../payload"
import { Icon } from "../slider/icons"

export type TSearchBarCs =
  | "root"
  | "openMob"
  | "inputRoot"
  | "input"
  | "inputOpenMobile"
  | "inputNav"
export const SearchBar: FC = (_props) => {
  const {
    api,
    cs: { SearchBar: cs },
    lang,
  } = useDi()

  const [text, setText] = useState<string>("")
  const [data, setData] = useState<TSearch | null>(null)
  const [popupIsOpen, setPopupOpen] = useState<boolean>(false)
  const [isLoading, setLoading] = useState<boolean>(false)
  const ref = useRef<HTMLInputElement>(null)

  const search = useCallback(
    debounce(async (newText: string) => {
      setLoading(true)
      const searchResult = await api.search(null, { text: newText.trim() })
      setData(searchResult)
      setLoading(false)
    }, 400),
    []
  )

  const setPopup = (open: boolean) => {
    setPopupOpen(open)
    scrollBody(!open)
    if (!ref.current) return
    if (open) {
      ref.current.focus()
      !data && search(text)
    } else {
      ref.current.blur()
    }
  }

  const onKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Escape") {
      e.preventDefault()
      setPopup(false)
    }
    // else if (e.key === "Enter") {
    //   history.push(ERouteUrl.search + "?" + SEARCH_SEARCH_PARAM + "=" + text)
    // }
  }

  return (
    <div className={clsx(cs.root, popupIsOpen && cs.openMob)}>
      <div className={cs.inputRoot}>
        <div className={cs.inputNav} onClick={() => setPopup(!popupIsOpen)}>
          <Icon
            name={popupIsOpen ? "arrowRight" : "search"}
            reverse={popupIsOpen}
            width={24}
          />
        </div>
        <input
          ref={ref}
          type="search"
          placeholder={lang.header__search_bar__placeholder}
          onChange={(e) => {
            setText(e.target.value)
            search(e.target.value)
          }}
          value={text}
          onFocus={() => setPopup(true)}
          onKeyDown={onKeyPress}
          className={clsx(cs.input, popupIsOpen && cs.inputOpenMobile)}
        />
      </div>
      {popupIsOpen && (
        <SearchPopup {...({ data, isLoading, setPopup } as any)} />
      )}
    </div>
  )
}
