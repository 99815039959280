import { scrollBody } from "@betnbet/front-sdk/dist/util/scroll-body"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import clsx from "clsx"
import React, { FC, useState } from "react"
import { useDi } from "../App/di"
import { Typography } from "../common/typography"
import { TLayoutBonus } from "../payload"
import { Icon } from "../slider/icons"
import { theme } from "../theme"
import NotificationsMenu from "./notifications-menu"

type TNotificationRoot = { bonuses: TLayoutBonus[] }
export const NotificationRoot: FC<TNotificationRoot> = ({ bonuses }) => {
  const {
    store,
    cs: { Header: cs },
  } = useDi()
  const { auth } = store
  const isDesktop = useMediaQuery(theme.breakpoints.up("sm")) || false

  const [isOpen, setOpen] = useState<boolean>(false)
  const toggleMenu = () => {
    if (isDesktop) return
    setOpen((prev) => {
      scrollBody(prev)
      return !prev
    })
  }

  if (!auth.userLoaded && bonuses.length === 0) return null

  return (
    <div className={cs.notificationContainer}>
      <div className={cs.iconContainer} onClick={toggleMenu}>
        <Icon
          width={24}
          name={auth.userLoaded ? "alert" : "gift"}
          className={clsx(
            store.auth.userLoaded ? cs.alert : cs.gift,
            isOpen && cs.notificationIsOpen,
            cs.notificationIcon
          )}
        />
        {auth.user && auth.user.nonReadableNotification > 0 && (
          <Typography name="btsMediun" className={cs.badge}>
            {auth.user.nonReadableNotification}
          </Typography>
        )}
      </div>
      <NotificationsMenu
        closeMenu={toggleMenu}
        bonuses={bonuses}
        isOpen={isOpen}
      />
    </div>
  )
}
