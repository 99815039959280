import { TLng, dictionary, dictionaryForDeclension } from "../lang"

export const getRussianDeclensionMinutes = (n: number, lng: TLng) => {
  const words: [string, string, string] = dictionaryForDeclension.minute[lng]
  return getRussianDeclension(n, words) + dictionary.reading_time[lng]
}

export const getRussianDeclensionDays = (n: number, lng: TLng) => {
  const words: [string, string, string] = dictionaryForDeclension.day[lng]
  return getRussianDeclension(n, words)
}

export const getRussianDeclension = (
  n: number,
  words: [string, string, string]
) => {
  const _n = Math.round(n)
  // https://gist.github.com/realmyst/1262561
  const cases = [2, 0, 1, 1, 1, 2]
  const word =
    words[_n % 100 > 4 && _n % 100 < 20 ? 2 : cases[_n % 10 < 5 ? _n % 10 : 5]]
  return _n + word
}
