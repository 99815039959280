import clsx from "clsx"
import React, { FC } from "react"
import { Link } from "react-router-dom"
import { useDi } from "../App/di"
import { TTypographyName } from "../theme"
import { TTextAlignCs } from "./common-styles"

type TTypographyProps = React.HTMLAttributes<HTMLDivElement> & {
  component?: string
  name: TTypographyName
  maxLines?: number
  align?: "left" | "center" | "right"
  to?: string
}
export const Typography: FC<TTypographyProps> = (props) => {
  const {
    component = "div",
    name,
    className,
    maxLines,
    to,
    style,
    align,
    ...divProps
  } = props
  const {
    cs: { Common: cs },
  } = useDi()

  return React.createElement(to ? (Link as any) : component, {
    className: clsx(
      cs[name],
      align && cs[("align_" + align) as TTextAlignCs],
      maxLines && cs.maxLines,
      className
    ),
    to,
    style: { ...style, WebkitLineClamp: maxLines },
    ...divProps,
  })
}
