import clsx from "clsx"
import React, { FC } from "react"
import { useDi } from "../App/di"
import { Img, TImgProps } from "../common/img"

type TCommentsAvatarProps = {
  name?: string
} & TImgProps

export const CommentsAvatar: FC<TCommentsAvatarProps> = (props) => {
  const { name, className, ...rest } = props
  const {
    cs: { CommentsAuthor: cs },
  } = useDi()
  return rest.src ? (
    <Img {...rest} className={clsx(cs.avatar_img, className)} alt={name} />
  ) : (
    <CommentsAvatarLetter
      name={name}
      className={clsx(className, cs.avatar_letter_size)}
    />
  )
}

export const CommentsAvatarLetter: FC<{
  name?: string
  className?: string
}> = ({ name, className }) => {
  if (!name) return null
  const {
    cs: { CommentsAuthor: cs },
  } = useDi()
  const firstLetter = name.trim() ? name.trim()[0].toUpperCase() : "A"
  return <div className={clsx(cs.avatar_letter, className)}>{firstLetter}</div>
}
