type TProbabilityOption<T> = { p: number; f: () => T }
export const probability = <T>(options: TProbabilityOption<T>[]): T => {
  const p_sum = options.reduce((acc, item) => acc + item.p, 0)
  const sum = Math.ceil(p_sum * 100)
  if (sum > 101 || sum < 99)
    throw new Error(`sum of probabilities ${sum} is not 100`) // TODO sum must be 100

  const random = Math.random()
  let result: T | null = null
  let random_sum = 0
  for (let option of options) {
    random_sum += option.p
    if (random <= random_sum) {
      result = option.f()
      break
    }
  }
  if (!result) throw new Error("no probability result")
  return result
}
