import { TErrorLog } from "@betnbet/front-sdk/dist/util/error"
import React from "react"

export class ErrorBoundary extends React.Component<{
  log: TErrorLog
}> {
  state = { hasError: false }

  componentDidCatch(error: any, info: any) {
    this.setState({ hasError: true })
    this.props.log(error, info)
  }

  render() {
    if (this.state.hasError) {
      return <h1>Oops</h1>
    }
    return this.props.children
  }
}
