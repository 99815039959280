import MuiButton, { ButtonProps } from "@material-ui/core/Button"
import clsx from "clsx"
import React, { FC } from "react"
import { Link, LinkProps } from "react-router-dom"
import { useDi } from "../App/di"

export type TButtonLinkProps = ButtonProps & LinkProps
export const ButtonLink: FC<TButtonLinkProps> = (props) => (
  <MuiButton component={Link as any} {...props} />
)

export type TButtonPropsSize = "large" | "medium" | "small"
export type TButtonPropsVariant = "primary" | "secondary" | "tertiary"
export type TButtonProps<T = string> =
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    component?: T
    size?: TButtonPropsSize
    variant?: TButtonPropsVariant
    to?: string
    target?: T extends "a" ? "_blank" : never
    href?: T extends "a" ? string : never
  }
export function Button<T = string>(props: TButtonProps<T>) {
  const {
    cs: { Common: commonCs, Button: cs },
  } = useDi()
  const {
    component = "button",
    size = "large",
    variant = "primary",
    className,
    to,
    ...buttonProps
  } = props
  return React.createElement(to ? (Link as any) : component, {
    className: clsx(
      cs.common,
      cs[variant],
      cs[size],
      size === "large" && commonCs.btsLarge,
      size === "medium" && commonCs.btsMediun,
      size === "small" && commonCs.btsSmall,
      buttonProps.disabled && cs.disabled,
      className
    ),
    to,
    ...buttonProps,
  })
}
