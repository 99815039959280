import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import React, { FC } from "react"
import { useDi } from "../App/di"
import { Select, TSelectOnChange } from "../common/select"
import { RegionNames } from "../const/regions"
import { TSvgName } from "../slider/icons"
import { TBnbTheme, bnbTheme } from "../theme"

type TFooterRegionToggle = { className?: string }
export const FooterRegionToggle: FC<TFooterRegionToggle> = ({ className }) => {
  const cs = useStyles()
  const { region, region_list, lang } = useDi()
  const current_region = region_list.find((r) => r.domain === region.domain)
  if (!current_region) return null
  const rest_regions = region_list.filter(
    (r) => r.domain !== region.domain && !r.is_hidden
  )

  const changeRegion: TSelectOnChange = async (region, e) => {
    if (window) {
      e.preventDefault()
      const hreflangAttribute = `[hreflang="${region.value}"]`
      const pageHasRegionVersion = document.querySelector(hreflangAttribute)
      const replace_path = pageHasRegionVersion ? location.pathname : "/"
      location.replace(region.href + replace_path)
    }
  }
  return (
    <Select
      className={clsx(cs.root, className)}
      options={rest_regions.map((r) => ({
        label: r.country,
        value: r.hreflang,
        href: `https://${r.domain}`,
        svg_name: r.name as TSvgName,
      }))}
      onChange={changeRegion}
      value={{
        label: current_region.country,
        value: current_region.hreflang,
        svg_name: current_region.name as RegionNames,
        aria_label: lang.footer__region_toggle__label,
      }}
    />
  )
}

const useStyles = makeStyles(
  (_theme: TBnbTheme) => {
    return {
      root: {
        position: "relative",
        background: bnbTheme.colors.light[2],
        width: 189,
        "& > input": {
          height: 36,
        },
      },
    }
  },
  { name: "FooterRegionToggle" }
)
