import { asyncComponent } from "@betnbet/front-sdk/dist/Common/Chunk"
import { scrollBody } from "@betnbet/front-sdk/dist/util/scroll-body"
// import { scrollBody } from "@betnbet/front-sdk/dist/util/scroll-body"
import { createElement, FC, useEffect, useState } from "react"
import { useDi } from "../App/di"
import { TWidgetPopupData } from "../payload"
import { TWidgetPopupProps } from "./widget-config"

const Popup = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "WidgetPopup" */
      /* webpackMode: "lazy" */
      "./widget-popup-el"
    )
)
export const WidgetPopup: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(true)
  const { api, store } = useDi()
  const { widget } = store
  const [popup, setPopup] = useState<TWidgetPopupData | null>(null)

  const closePopup = () => {
    setIsOpen(false)
    setPopup(null)
    scrollBody(true)
  }

  useEffect(() => {
    let timeout: NodeJS.Timeout

    const loadPopup = async () => {
      const res = await api.popupWidget(null, {
        bk_slug: widget.popup.bkSlug,
      })
      if (res.popup) {
        await Popup.load()
        scrollBody(false)
        setPopup(res.popup)
      }
    }

    if (widget.popup.show && widget.popup.bkSlug && isOpen) {
      timeout = setTimeout(loadPopup, 2000)
    }

    return () => clearTimeout(timeout)
  }, [widget.popup.bkSlug])

  if (!popup || !isOpen) return null
  return createElement(Popup, {
    widget: popup,
    isOpen: isOpen,
    close: () => closePopup(),
  } as TWidgetPopupProps as any)
}
