import { FC, useEffect } from "react"
import { useDi } from "../App/di"
export const SignupRoot: FC = () => {
  const {
    store: { auth },
  } = useDi()
  useEffect(() => {
    if (!auth.userLoaded) {
      auth.login()
    }
  }, [])
  return null
}
