import React, { FC } from "react"
import { useDi } from "../App/di"

export type TImgProps = React.ImgHTMLAttributes<HTMLImageElement> & {
  width: number
  desctopWidth?: number
  imgAttr?: {
    width: number
    height: number
  }
}

export const Img: FC<TImgProps> = (props) => {
  const {
    src = "",
    width,
    desctopWidth,
    imgAttr,
    loading = "lazy",
    ...rest
  } = props

  const { env, theme } = useDi()
  const { CDN, WEBP } = env

  const isSvg = src.endsWith(".svg")
  const isGif = src.endsWith(".gif")
  if (isSvg || isGif) {
    return (
      <img
        itemProp="image"
        loading={loading}
        src={CDN + "/" + src}
        {...rest}
        {...imgAttr}
      />
    )
  }

  const imgProps: TImgDefaultProps = { image_name: src, prefix: CDN, WEBP }
  const defaultSrc = getImgSrc({ ...imgProps, WEBP: undefined, width })

  let srcSet = ""
  let sizes: string | undefined = undefined

  if (desctopWidth) {
    const x1pxMob = getImgSrc({ ...imgProps, width: width })
    const x2pxMob = getImgSrc({ ...imgProps, width: width * 2 })
    const x1pxDesc = getImgSrc({ ...imgProps, width: desctopWidth })
    const x2pxDesc = getImgSrc({ ...imgProps, width: desctopWidth * 2 })

    srcSet = `${x1pxMob} ${width}w, ${x2pxMob} ${
      width * 2
    }w, ${x1pxDesc} ${desctopWidth}w, ${x2pxDesc} ${desctopWidth * 2}w`
    sizes = `(max-width: ${theme.breakpoints.values.sm}px) ${width}px, ${desctopWidth}px`
  } else {
    const x1px = getImgSrc({ ...imgProps, width })
    const x2px = getImgSrc({ ...imgProps, width: width * 2 })
    srcSet = `${x1px} ${width}w, ${x2px} ${width * 2}w`
    sizes = `${width}px`
  }
  return (
    <img
      itemProp="image"
      loading={loading}
      src={defaultSrc}
      srcSet={srcSet}
      sizes={sizes}
      {...rest}
      {...imgAttr}
    />
  )
}

type TImgSrcProps = {
  image_name: string
  prefix: string
  width?: number
  WEBP?: boolean
}

type TImgDefaultProps = Omit<TImgSrcProps, "width">

type TgetImgSrc = (props: TImgSrcProps) => string
export const getImgSrc: TgetImgSrc = ({ image_name, prefix, width, WEBP }) => {
  if (!image_name) return ""
  if (!width) return `${prefix}/${WEBP ? "-f_webp-" : ""}q_80/${image_name}`
  return `${prefix}/w_${width}${WEBP ? "-f_webp" : ""}-q_80/${image_name}`
}
