import { makeStyles } from "@material-ui/core/styles"
import clsx from "clsx"
import { To } from "history"
import React, { FC, useCallback, useRef, useState } from "react"
import { Link } from "react-router-dom"
import { useDi } from "../App/di"
import { Icon, TSvgName } from "../slider/icons"
import { bnbTheme, TBnbTheme } from "../theme"
import { Img } from "./img"
import { Typography } from "./typography"

type TMode = "up" | "down"

export type TSelectOption = {
  label: string
  value: string
  svg_name?: TSvgName
  image_url?: string
  link?: To
  href?: string
  count?: number
  aria_label?: string
}
export type TSelectOnChange = (
  value: TSelectOption,
  e: React.MouseEvent<HTMLElement, MouseEvent>
) => Promise<void>
type TSelect = {
  options: TSelectOption[]
  className?: string
  onChange?: TSelectOnChange
  value: TSelectOption
}
export const Select: FC<TSelect> = (props) => {
  const { options, className, onChange, value } = props
  const cs = useStyles()
  const {
    cs: { Common: commonCs },
  } = useDi()

  const [isOpen, setOpen] = useState<boolean>(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const optionsRef = useRef<HTMLDivElement>(null)
  const mode = useRef<TMode>("down")

  const setSelectValue = useCallback(
    async (
      option: TSelectOption,
      e: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      await onChange?.(option, e)
      setOpen(false)
    },
    []
  )

  const setMode = (open: boolean): TMode | undefined => {
    const inputCurrent = inputRef.current
    const optionsCurrent = optionsRef.current
    if (inputCurrent && optionsCurrent && open) {
      const { bottom } = inputCurrent.getBoundingClientRect()
      const offsetBottom = window.innerHeight - bottom
      const mode: TMode = offsetBottom <= options_height + 20 ? "up" : "down"
      optionsCurrent.style.animationDuration = "300ms"
      return mode
    }
  }

  const openSelect = (open: boolean) => {
    const currentMode = setMode(open)
    if (currentMode) mode.current = currentMode
    setOpen(open)
  }

  return (
    <div className={clsx(cs.root, className)} tabIndex={-1}>
      <Icon
        name="arrow"
        width={20}
        className={clsx(cs.arrow, isOpen && cs.arrowOpen)}
      />
      {value.svg_name && (
        <Icon
          className={clsx(cs.inputIcon, cs.inputPic)}
          name={value.svg_name}
          width={20}
        />
      )}
      {value.image_url && (
        <Img
          className={clsx(cs.inputImg, cs.inputPic)}
          width={20}
          src={value.image_url}
          imgAttr={{ width: 20, height: 20 }}
          alt={value.label}
        />
      )}
      <input
        ref={inputRef}
        onMouseDown={() => openSelect(!isOpen)}
        onBlur={() => isOpen && openSelect(false)}
        className={clsx(
          cs.input,
          commonCs.btsMediun,
          value.svg_name && cs.inputHasIcon,
          value.image_url && cs.inputHasIcon
        )}
        readOnly
        value={value.label}
        aria-label={value.aria_label}
      />
      <div
        ref={optionsRef}
        className={clsx(
          cs.options,
          commonCs.scrollBar,
          cs.animationCommon,
          isOpen
            ? mode.current === "up"
              ? cs.showUp
              : cs.showDown
            : mode.current === "up"
            ? cs.hideDown
            : cs.hideUp
        )}
      >
        {options.map((opt) =>
          React.createElement(opt.link ? Link : opt.href ? "a" : "div", {
            key: opt.label,
            onClick: (e) => setSelectValue(opt, e),
            className: cs.option,
            to: opt.link as any,
            ["href" as any]: opt.href,
            children: (
              <>
                {opt.svg_name && (
                  <Icon
                    className={cs.optionIcon}
                    name={opt.svg_name}
                    width={20}
                  />
                )}
                {opt.image_url && (
                  <Img
                    className={cs.optionImg}
                    width={20}
                    src={opt.image_url}
                    imgAttr={{ width: 20, height: 20 }}
                    alt={opt.label}
                  />
                )}
                <Typography name="btsMediun">{opt.label}</Typography>
                {typeof opt.count === "number" && (
                  <Typography name="btsMediun" className={cs.count}>
                    {opt.count}
                  </Typography>
                )}
              </>
            ),
          })
        )}
      </div>
    </div>
  )
}

const option_height = 40
const options_height = option_height * 6

const useStyles = makeStyles(
  (_theme: TBnbTheme) => {
    return {
      root: {
        position: "relative",
        height: "fit-content",
        width: "fit-content",
        outline: "none",
        textTransform: "uppercase",
        background: "#fff",
        borderRadius: 8,
      },
      input: {
        position: "relative",
        padding: "0 40px 0 10px",
        height: 40,
        background: "transparent",
        outline: "none",
        border: "none",
        borderRadius: 8,
        transition: ".2s",
        cursor: "pointer",
        width: "100%",
        "&:hover": { boxShadow: `0 0 0 1px ${bnbTheme.colors.primary[1]}` },
        "&:focus": { boxShadow: `0 0 0 1px ${bnbTheme.colors.primary[1]}` },
      },
      inputHasIcon: { padding: "0 40px" },
      inputPic: {
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        left: 10,
        zIndex: 0,
        width: 20,
        height: 20,
      },
      inputIcon: {
        fill: bnbTheme.colors.dark[3],
      },
      inputImg: {
        opacity: 0.2,
      },
      showDown: { animationName: "$showDown" },
      hideUp: { animationName: "$hideUp" },
      showUp: { animationName: "$showUp" },
      hideDown: { animationName: "$hideDown" },
      animationCommon: {
        animationTimingFunction: "cubic-bezier(0.6, 0, 0.4, 0.9);",
        animationDirection: "alternate",
        animationIterationCount: 1,
        animationFillMode: "forwards",
      },

      arrow: {
        position: "absolute",
        right: 10,
        top: "50%",
        zIndex: 0,
        transition: ".1s",
        transform: "rotate(90deg) rotateY(0deg) translateX(-50%)",
      },
      arrowOpen: {
        transform: "rotate(90deg) rotateY(180deg) translateX(50%)",
        top: "50%",
      },
      options: {
        transition: ".2s",
        position: "absolute",
        opacity: 0,
        left: 0,
        right: 0,
        background: "#fff",
        zIndex: 3,
        border: `1px solid ${bnbTheme.colors.light[2]}`,
        boxShadow: "0px 4px 14px rgba(0, 0, 0, 0.1)",
        borderRadius: 8,
        overflowY: "auto",
        overflowX: "hidden",
        maxHeight: options_height,
        boxSizing: "content-box",
      },
      option: {
        display: "flex",
        alignItems: "center",
        height: option_height,
        padding: "0 14px",
        cursor: "pointer",
        "&:hover": {
          background: bnbTheme.colors.primary[2],
          "& $optionIcon": { fill: bnbTheme.colors.dark[1] },
          "& $optionImg": { opacity: 1 },
          "& $count": { color: "#000" },
        },
      },
      optionIcon: {
        marginRight: 10,
        fill: bnbTheme.colors.dark[3],
      },
      optionImg: {
        marginRight: 10,
        opacity: "0.2",
      },
      count: {
        color: bnbTheme.colors.dark[3],
        marginLeft: 4,
      },
      "@keyframes showUp": {
        "0%": { opacity: 0, visibility: "hidden", bottom: "100%" },
        "100%": {
          opacity: 1,
          visibility: "visible",
          bottom: "calc(100% + 8px)",
        },
      },
      "@keyframes hideDown": {
        "0%": { opacity: 1, visibility: "visible", bottom: "calc(100% + 8px)" },
        "100%": { opacity: 0, visibility: "hidden", bottom: "100%" },
      },
      "@keyframes showDown": {
        "0%": { opacity: 0, visibility: "hidden", top: "calc(100%)" },
        "100%": { opacity: 1, visibility: "visible", top: "calc(100% + 8px)" },
      },
      "@keyframes hideUp": {
        "0%": { opacity: 1, visibility: "visible", top: "calc(100% + 8px)" },
        "100%": { opacity: 0, visibility: "hidden", top: "calc(100%)" },
      },
    }
  },
  { name: "ScrollSnapSlider.displayName" }
)
