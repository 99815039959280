import { TApi, TApiKey } from "./graphql-sdk"

const API_PREFIX = process.env.IS_WORKER ? "https://betnbet.ru" : ""

export const api = new Proxy({} as TApi, {
  get: function (_target, name: TApiKey, _receiver) {
    return async (signal?: AbortSignal, variables?: any) => {
      const url = API_PREFIX + "/api/" + name
      const hasFile = variables
        ? Object.values(variables).some((item) => item instanceof File)
        : false

      if (hasFile) {
        // TODO refactor to const formData = new FormData and forEach
        const formData = Object.entries(variables).reduce(
          (prev, [key, value]) => {
            if (value instanceof File) {
              prev.append(key, value as any)
            } else {
              prev.append(key, JSON.stringify(value))
            }
            return prev
          },
          new FormData()
        )
        const result = await fetch(url, {
          method: "POST",
          body: formData,
        })
        return await result.json()
      } else {
        let result: Response | null = null
        const body = variables && JSON.stringify(variables)
        const headers = { "content-type": "application/json; charset=utf-8" }
        try {
          if (process.env.IS_WORKER) {
            if (signal) {
              // TODO check where signal goes from
              console.log("signal in worker", url, signal)
            }
            result = await fetch(url, {
              method: "POST",
              body,
              headers,
            })
          } else {
            result = await fetch(url, {
              method: "POST",
              body,
              signal,
              headers,
            })
          }
          if (!result.ok) {
            throw new Error("FetchResponseNotOkError")
          }
          const json = await result.json()
          return json
        } catch (e) {
          console.log("fetch catch", result?.url, result?.ok, result?.status)
          const text = await result?.text()
          console.log("fetch catch text", text)
          throw e
        }
      }
    }
  },
})
