import React, { FC } from "react"

type TWidgetMarkProps = {
  mark: string | null
  className?: string
}

export const WidgetMark: FC<TWidgetMarkProps> = ({ mark, className }) => {
  return <div className={className}>{mark}</div>
}
