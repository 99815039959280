import { asyncComponent } from "@betnbet/front-sdk/dist/Common/Chunk"
import React, { FC } from "react"
import { useDi } from "../App/di"
import { LoaderProgress } from "../App/loader-progress"
import { WidgetCatfish } from "../widget/widget-catfish"

const SnackBar = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "SnackBar" */
      /* webpackMode: "lazy" */
      "../snack/snack-bar"
    )
)

export const UserMenu = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "UserMenu" */
      /* webpackMode: "lazy" */
      "../header/header-user-menu"
    )
)

export const SearchPopup = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "SearchPopup" */
      /* webpackMode: "lazy" */
      "../search/search-models"
    )
)

export const Modal = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "Modal" */
      /* webpackMode: "lazy" */
      "../common/modal"
    )
)

const Locker = asyncComponent(
  () =>
    import(
      /* webpackChunkName: "Locker" */
      /* webpackMode: "lazy" */
      "../common/page-loader"
    )
)

export const LayoutLazyComponents: FC = () => {
  const { story, store } = useDi()
  const { modal, pageLoader, snack } = store

  return (
    <>
      <WidgetCatfish />
      {snack.current && <SnackBar />}
      {pageLoader.isLoading && <Locker />}
      <LoaderProgress isLoading={story.loading} />
      {modal.show && <Modal />}
    </>
  )
}
